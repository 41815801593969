<template>
	<div>
		<a-modal :visible="visible" :title="title" :closable="false">
			<template slot="footer">
				<a-button key="back" @click="onCancel"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="handleSubmit"> {{ submitLabel }} </a-button>
			</template>
			<a-spin :tip="spinnerLoaderLabel" size="small" :spinning="spinnerLoader">
				<a-form layout="vertical" :form="form" @submit="handleSubmit">
					<div class="row">
						<div class="col-md-6">
							<a-form-item label="Nombre de la Sucursal">
								<a-input
									v-decorator="[
										'branch_office_name',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]"
									autocomplete="off"
								/>
							</a-form-item>
						</div>
						<div class="col-md-6">
							<a-form-item label="Responsable">
								<a-select
									v-decorator="[
										'attendant',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]"
								>
									<a-select-option v-for="(user, index) in usersList" :key="index" :value="user.user_id">{{ user.fullName }}</a-select-option>
								</a-select>
							</a-form-item>
						</div>
						<div class="col-md-12">
							<a-form-item label="Dirección">
								<a-input v-decorator="['location']" autocomplete="off" />
							</a-form-item>
						</div>
					</div>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>
<script>
//
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'

export default {
	name: 'insertOrUpdateBranchOffices',
	components: {},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: '',
		},
		payload: {
			type: Object,
			default: () => {
				return {}
			},
		},
	},
	computed: {
		...mapGetters('users', ['usersList']),
		spinnerLoaderLabel() {
			return this.$store.state.branchOffices.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.branchOffices.spinnerLoader
		},
	},
	data() {
		return {
			form: this.$form.createForm(this),
			submitLabel: '',
		}
	},
	methods: {
		onCancel() {
			this.$emit('closeModal')
			this.form.resetFields()
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields((err, values) => {
				if (!err) {
					//
					let payload = {
						...values,
					}
					if (utilities.objectValidate(this.payload, 'id', false)) {
						payload.id = this.payload.id
						this.$store
							.dispatch('branchOffices/UPDATE', payload)
							.then((response) => {
								// console.log('bankingInstitutions/UPDATE::response-->', response)
								this.onCancel()
							})
							.catch((error) => {
								// console.log('bankingInstitutions/UPDATE::error-->', error)
							})
					} else {
						this.$store
							.dispatch('branchOffices/CREATE', payload)
							.then((response) => {
								// console.log('bankingInstitutions/CREATE::response-->', response)
								this.onCancel()
							})
							.catch((error) => {
								// console.log('bankingInstitutions/CREATE::error-->', error)
							})
					}
				}
			})
		},
	},
	watch: {
		payload(newValue) {
			setTimeout(() => {
				if (utilities.objectValidate(newValue, 'id', false)) {
					this.form.setFieldsValue({
						branch_office_name: utilities.objectValidate(newValue, 'branch_office_name', ''),
						attendant: utilities.objectValidate(newValue, 'attendant', ''),
						location: utilities.objectValidate(newValue, 'location', ''),
					})
					this.submitLabel = 'Actualizar'
				} else {
					this.submitLabel = 'Guardar'
				}
			}, 100)
		},
		visible(isVisible) {
			if (isVisible && this.usersList.length == 0) {
				this.$store.dispatch('users/GET')
			}
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>